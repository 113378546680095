<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">用户协议</div>
      <div class="title1">
        您同意在我们的网站上注册账户或以其他方式使用我们的服务，即表示您同意以下条款和条件(“条款”)。如果您不同意这些条款，请勿注册账户或以其他方式使用我们的服务。
      </div>
      <div class="title1">
        请注意，您对服务的使用也受我们隐私政策的约束。本公司保留随时更新条款和隐私政策的权利，恕不另行通知。
      </div>

      <div class="con">
        <h3> 第一条 特别声明</h3> <br>
        1 .请您认真阅读本《用户协议》（以下简称“本协议”），确保您充分理解本协议中包括免除本网站责任的条款及限制用户权利的条款。这些条款应在您所在国家法律所允许的范围内最大程度地适用。您同意在我们的网站上注册账号或以其他方式使用我们的服务，即表示您同意接受本协议各项条款的约束。如果您不同意这些条款，请勿使用我们的服务。<br>
        2 .请注意，本协议可由本站随时更新， 更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，更新后的协议条款自动对于已注册用户具有法律约束力。<br>
        3 .在您遵守本协议的前提下，本站将给予您一项个人的、不可转让及非排他性的许可，以使用本站（以下简称“本网站”或“本本网站”）。<br>
        4 .本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除一切冲突法的适用。<br>
  
        <h3>第二条 重要概念定义</h3><br>
        1.用户：是指通过本网站提供的合法途径，获得本网站非商业使用性质之授权，从而使用本网站或接受本网站服务的自然人。在本协议中，还可能用“您”来指代用户。<br>
        2.本网站：是指可在个人电脑及移动终端上访问的本网站内容（www.cs2steamskins.com)。<br>
        3.饰品：指STEAM平台（http://store.steampowered.com/）所提供的产品内所存在的、可在该平台内的不同账号之间转移的角色、资源、皮肤等可归属于特定主体的财产或财产性权益（包括但不限于其中的武器、装备、饰品等）。本协议以及本网站相关页面中所描述的“提货”、 “创建交易”等用词，均指且仅指前述财产或财产性权益及其交易对价在特定主体之间的转让行为。<br>
        4.账号：用户在本网站上为访问服务而创建的一个或多个账户。<br>
       
        <h3>第三条 账号注册规范</h3><br>
        1 .您必须创建账号才能访问和使用服务，您必须在创建账号时提供完整、准确和最新的信息。注册时所填写的个人信息如有变动，用户需通过“个人中心”界面及时更改。 用户因未及时更改造成的损失，本站概不负责。  请勿使用违反法律规定和破坏社会公序良俗的头像及名称；单个STEAM交易链接只可绑定一个网站账号，发现违规本网站有权作封号处理，充值资金将予以核算后退还；造成本网站经济损失的，本网站保留追究法律责任的权利。<br>
        2 .如果注册申请者有被本网站封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，网站有权拒绝其注册申请。<br>
        3 .您同意本网站：有权限制任何用户或关联公司能够创建的账号数量。<br>
        4.您同意本网站保留：随时以任何理由终止您的账号的权利和唯一决定权。<br/>

        <h3>第四条 用户使用资格条款</h3><br>
          访问本网站服务，您需保证:<br>
          1.年龄至少为18岁，或至少为适用法律规定的最低年龄，以便我们无需经过您父母同意即可合法向您提供服务；<br>
          2.未有过因违法操作，被暂停交易的记录；<br>
          3.拥有全部必要的同意和授权，以执行从您的账户发起的全部交易；<br>
          4.提供真实、准确、完整的注册资料；<br>
          5.您同意在访问服务时遵守所有适用的法律和法规。您同意不将服务用于任何非法活动，包括但不限于非法赌博、洗钱、欺诈、敲诈、勒索、数据交换、资助恐怖主义或其他暴力活动。已审阅本协议所载的风险披露声明，并同意承担其中所述的所有风险。<br>
        本公司有权随时终止先前提供的通证可用性。在这种情况下，公司将为您提供收回这些通证的机会。<br>
        
        
        <h3>第五条 服务内容约定</h3><br>
        1 .您确认并同意，本站的预估价、参考价、市场价等，仅仅是为了更好地服务用户而提供给用户的参考信息，价格来源于STEAM平台（http://store.steampowered.com/）任何时候本网站的任何行为均不得被认为是对交易标的价格的合理性、交易标的来源的合法性等进行的任何明示或默示的担保或保证。<br>
        2 .在遵守相关法律法规前提下，根据合作广告主的要求，本网站有权决定用户在“本站”应用界面上看到的广告内容及发布方式。<br>
        3、收费政策。本网站有权就不同的产品和服务制定不同的收费标准和收费方式，且有权就所提供的产品和服务的内容确定不同的收费标准和收费方式。具体收费方式、收费标准，将会在本网站或移动端网站进行标明。用户有义务在消费前仔细阅读，一旦用户选择使用相关服务、虚拟物品使用权或者接受相关服务，则表明用户已经充分理解并接受相应的收费方式和定价标准。<br>
        4 .本网站前端饰品开箱动画可能因网络延迟或设备兼容性性等原因导致显示出错，如用户不幸遭遇上述情况（譬如动画结束悬停饰品A但结果为饰品B）请用户以开箱结果（饰品B）为准。<br>



        <h3>第六条 用户使用须知：</h3><br>
        用户如有违反下述使用规范，侵害本站利益的行为，核实用户行为后本站有权封停账号，清空违规账号的全部余额及背包内物品：:<br>
        1.提交、发布虚假信息，或盗用他人资料。<br>
        2.利用任何手段批量建立账号，积累或转移站内资产，不当得利；<br>
        3.利用饰品价格波动或网站漏洞反复刷取饰品，积累资产，不当得利；<br>
        4.侵害本网站以及外部第三方的知识产权。<br>
        
        
        
        <!-- <h3>7 .隐私政策相关</h3><br>
        你的隐私对我们很重要。但是，我们可能被迫根据相应的法律、规则或法规共享您的信息。有关详细信息，请参阅我们的《隐私政策》。<br>
        <h3>8 .反洗钱和验证用户身份政策</h3><br>
        公司执行银行层面的(“验证用户身份”)流程，以遵守反洗钱( AML )法律。该公司对照政府观察名单对用户数据进行交叉核对。如果用户或用户的交易被标记为可疑，公司将要求用户提供额外的身份证明，并可暂停该用户的任何交易、存款或提款，直至收到令公司满意的身份证明。<br>
        公司对用户验证级别使用内部政策。本公司有权利为任何用户确定适当验证级别和标准的，以及在不事先通知的情况下降低用户级别的权利。公司可随时执行措施，根据国籍、居住国或任何其他因素限制核查水平和标准。<br>
        您同意，如果无法达到所需的验证级别，则可能无法存放或提取通证。您同意独自承担责任，不要求公司或其关联公司对此类损失承担责任。<br>
        您特此授权公司直接（或通过第三方）进行任何我们所认为必要的查询，以验证您的身份并/或防止欺诈行为的发生，包括查询公开报告中包含的身份信息(例如您的姓名、地址、过去地址或出生日期)，查询与您关联的银行账户相关的账户信息(例如姓名或账户余额)，并根据此类查询和报告的结果采取我们认为合理且必要的行动。您还授权可能收到此类查询或请求的任何和所有第三方对此类查询或请求做出全面响应。<br>
        <h3>9 .不可转让性</h3><br>
        虽然向用户提供的账户和服务不可转让，但公司有权将账户和服务的所有权、利益进行义务转让、转让或出售给任何人，这些条款对公司或其贷款人的继承人和受让人(如有)继续有效。<br>
        <h3>10 .永久性撤回服务</h3><br>
        公司有权暂停或终止您对服务的访问。以及如果公司根据适用的法律、规则或法规，怀疑您或其他人使用您的账户从事非法活动，则可以停用或取消您的账户。在这种情况下，除非另有相应的法律禁止，否则您可以在账户停用或取消后 60天内转让与您的账户相关的通证。如果您的账户在被取消或暂停时，任何交易处于挂起状态，则可根据需要取消或退还此类交易。<br>
        通证返还流程：公司注销您的账户——您授权公司取消或暂停挂起交易——公司通过服务或应用程序向您发出通知——与交易相关联的通证会返回公司为您保留的钱包地址。<br>
        如果本公司无法通过合理的商业努力将您的通证归还给CoinFit为您存档的钱包地址，本公司可根据香港无人认领财产法向适用的政府机构报告和汇寄通证。<br>
        <h3>11 .免责声明与免责事项</h3><br>
        用户了解并同意，在任何情况下，我们不就以下各事项承担责任：<br>
        (1)收入的损失；<br>
        (2)交易利润或合同损失；<br>
        (3)业务中断；<br>
        (4)预期可节省的货币的损失；<br>
        (5)信息的损失；<br>
        (6)机会、商誉或声誉的损失；<br>
        (7)数据的损坏或损失；<br>
        (8)购买替代产品或服务的成本；<br>
        (9)数字资产消亡或退出市场；<br>
        (10)任何由于侵权（包括过失）、违约或其他任何原因产生的间接的、特殊的或附带性的损失或损害，不论这种损失或损害是否可以为我们合理预见；不论我们是否事先被告知存在此种损失或损害的可能性。<br>
        （1）条至（10）条均是彼此独立的。<br>
        用户了解并同意，我们不对因下述任一情况而导致用户的任何损害赔偿承担责任：<br>
        (1)我们有合理理由认为用户的具体交易事项可能存在重大违法或违约情形。<br>
        (2) 我们有合理的理由认为用户在我们的行为涉嫌违法或不当。<br>
        (3) 通过我们服务购买或获取任何数据、信息或进行交易等行为或替代行为产生的费用及损失。<br>
        (4)用户对本网站服务的误解。<br>
        (5)非因我们的原因而造成用户信息被泄露、毁损或灭失。<br>
        (6)任何非因我们的原因而引起的与我们提供的服务有关的其它损失。<br>
        (7)我们不能保证我们包含的全部信息、程序、文本等完全安全，不受任何病毒、木马等恶意程序的干扰和破坏，故用户登陆、使用我们任何服务或下载及使用该下载的任何程序、信息、数据等均是用户个人的决定并自行承担风险及可能产生的损失。<br>
        (8)我们对我们链接的任何第三方网站的任何信息、产品及业务及其他任何形式的不属于我们的主体的内容等不做任何保证和承诺，用户如果使用第三方网站提供的任何服务、信息及产品等均为用户个人决定且承担由此产生的一切责任。<br>
        (9)我们对于用户使用我们服务不做任何明示或暗示的保证，包括但不限于我们提供服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，我们也不对我们提供的服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。是否登陆或使用我们提供的服务是用户个人的决定且自行承担风险及可能产生的损失。我们对于数字资产的市场、价值及价格等不做任何明示或暗示的保证，用户理解并了解数字资产市场是不稳定的，价格和价值随时会大幅波动或崩盘，交易数字资产是用户个人的自由选择及决定且自行承担风险及可能产生的损失。<br>
        (10)协议中规定的我们的保证和承诺是由我们就本协议和我们提供的服务的唯一保证和陈述，并取代任何其他途径和方式产生的保证和承诺，无论是书面的或口头的，​​明示的或暗示的。所有这些保证和陈述仅仅代表我们自身的承诺和保证，并不保证任何第三方遵守本协议中的保证和承诺。<br>
        (11)我们并不放弃本协议中未提及的在法律适用的最大范围内我们享有的限制、免除或抵销我们损害赔偿责任的任何权利。<br>
        <h3>12 .适用法律和争议解决</h3><br>
        本使用条款应受香港法律管辖，并应在所有方面解释为香港合同。由本使用条款引起或与本使用条款相关的任何索赔或诉讼(包括侵权索赔)应受香港法律管辖，并根据香港法律进行解释和执行。<br>
        为加快并控制争议成本，您同意，您使用服务或本条款、或您使用服务或本条款相关的任何法律引起的索赔，包括本条款的形成、有效性、可执行性、范围或适用性(以下简称“索赔”)将按以下方式解决:除了禁令或衡平法上的救济索赔或知识产权索赔(可在任何主管法院提起而无需出具保函)之外，您使用服务引起的任何争议应根据香港国际仲裁中心(以下简称“仲裁中心”)通过保密、有约束力的仲裁最终解决。这意味着，除知识产权诉讼之外的所有索赔，如版权或商标侵权诉讼，或寻求非金钱救济的索赔，都将受到具有约束力的仲裁。仲裁应以英文在香港进行，仲裁决定可在任何法院执行。<br>
        要开始仲裁程序，您必须通过邮件发送一封请求仲裁的信函，并向我们描述您的索赔要求.<br>
        <h3>13 .没有集体诉讼</h3><br>
        您和公司只能以您或其个人身份对另一方提出索赔，而不能在任何声称的类别、代表、合并或多索赔人诉讼中作为原告或类别成员提出索赔。<br>
        你和公司都放弃了陪审团审判的权利。<br>
        <h3>14 .可分性；弃权</h3><br>
        如果本条款中的任何条款被视为非法、无效或由于任何原因无法执行，则该条款应被视为可与本条款分离，且不会影响任何剩余条款的有效性和执行性。本公司延迟或未能执行其任何权利不应构成对该等权利的持续放弃。<br>
        <h3>15 .整合</h3><br>
        本条款以及公司发布的任何政策或运营规则构成您与公司之间的完整协议和了解，并取代您与公司之间以前或同时期的任何形式的协议、沟通和建议(包括但不限于本条款的任何以前版本)。<br>
        <h3>16 .不可抗力因素</h3><br>
        除上述适用免责声明外，如果由于超出公司合理控制范围的原因(包括但不限于天灾、任何政府行为、战争或其他敌对行为、内乱、火灾、水灾、暴风雪、地震、爆炸、禁运、恐怖主义行为、停电、设备故障、劳资纠纷或争议、任何第三方数据提供商或其他第三方信息提供商的行为、第三方软件或通信方法中断)而导致中断和/或延迟，公司应免除履行本条款。<br>
        <h3>17 .修正</h3><br>
        公司可随时修改、删除或以其他方式修改本使用条款和隐私政策中的任何条款；前提是，公司至少在修改前七天，通过您在公司存档的电子邮件向您发出通知。<br>
        <h3>18 .暂停和终止服务</h3><br>
        公司可出于任何原因，在事先通知或不事先通知您的情况下，暂停和终止服务。您承认并同意，如果市场状况不稳定、不合理，公司可在事先通知或不通知您的情况下，通过启动交易限制或其他适当的措施，终止服务。<br> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    margin-top: 46px;
    margin-bottom: 88px;
    font-size: 18px;
    color: #848492;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
  .title1 {
    color: #c3c3e2;
    font-size: 20px;
    padding-bottom: 10px;
  }
}
</style>