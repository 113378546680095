import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import scroll from 'vue-seamless-scroll'
import 'element-ui/lib/theme-chalk/index.css';
const bus = new Vue();
Vue.prototype.$bus = bus

import Axios from 'axios'
Vue.config.productionTip = false
Vue.prototype.$axios = Axios

Axios.defaults.baseURL = ''
Axios.defaults.timeout = 60000;
Axios.defaults.retry = 3;

Axios.interceptors.request.use((config) => {
  const token = store.state.token
  if (token) {
    config.headers['token'] = token
  }
  return config
}, config => {
  return Promise.reject(config)
})

Axios.interceptors.response.use((response) => {
  const code = response.data.code || response.data.status
  if (![1, 0, 5].includes(code)) {
    if (code == 401) {
      store.commit("getLogin", true);
    }
    return Promise.reject(response)
  }
  return response
}, (response) => {
  console.log(response)
  return Promise.reject(response)
})

import qs from 'qs';
Vue.prototype.$qs = qs;




Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.use(scroll)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

