import { render, staticRenderFns } from "./my_slide_page.vue?vue&type=template&id=0242e515&scoped=true"
import script from "./my_slide_page.vue?vue&type=script&lang=js"
export * from "./my_slide_page.vue?vue&type=script&lang=js"
import style0 from "./my_slide_page.vue?vue&type=style&index=0&id=0242e515&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0242e515",
  null
  
)

export default component.exports