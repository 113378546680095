<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">隐私条款</div>
      <div class="con">请在使用本网站的服务前，仔细阅读并了解本《隐私政策》（以下统称“政策”或者“隐私政策”）。<br>
        <h3>一、本网站非常重视保护使用本站产品和服务之的用户（以下统称“用户”或“您”）的隐私。您在使用本站服务时，我们可能会收集和使用您的相关信息，我们将全力保护您的个人信息安全可靠。我们致力于维护您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</h3><br>
        
        <h3>二、本政策适用于本网站提供的一切服务。在您使用本站产品或者服务前，请您务必认真阅读本政策，在充分理解并同意后使用相关产品或者服务。一旦您点击确认同意本隐私政策，开始使用本站任何产品或者服务，即表示您已充分理解并同意我们按照《隐私政策》收集、使用、储存和分享您的个人信息。</h3>
        <h3>三、“个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。请您理解并同意，前述基本信息以及其他无法通过其单独或者与其他信息结果识别您个人身份或活动情况的信息不属于您的个人信息。</h3>
        <h3>四、我们如何收集和使用您的个人信息：</h3>
        1、为实现向您提供我们服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；<br>
        2、为实现向您提供我们服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。
        3、在您注册成为本站用户以及使用服务过程中，经您授权我们可以向本站的关联方、合作伙伴等获得您的有关信息，以便于我们处理您的订单并保证服务顺利完成，或者更好地预防互联网欺诈。<br>
        4、我们在您使用第三方账号登录时，会从第三方获取您共享的账号信息（如昵称、头像、地区、性别），并在您同意本隐私政策后将您的第三方账户与本站账户进行绑定。<br>
        我们会出于以下目的，收集和使用您的个人信息：<br>
        4.1帮助您完成注册并使用基础用户服务：<br>
        我们通过本站账户为您提供基础用户服务。为注册成为用户并使用我们的基础服务，您需要提供电子邮箱、STEAM账户、拟使用的用户名和密码用于创建本站账户。如果您仅需使用浏览、搜索服务，您不需要注册成为我们的用户及提供上述信息。
        对于需要通过本站账户才能使用的服务，我们可能会根据您提供的上述信息校验您的
        用户身份，确保我们是在为您本人提供服务。<br>
        4.2使用附加用户服务：
        授权登录：经您同意后，我们可能向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的本站账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使用您的相关信息。
        <br>4.3账户信息展示：
        如果您已拥有本站账户，我们可能会在本站显示您的上述个人信息（实人认证实名认证仅显示认证是否通过的结果），以及您在本站或与本站账户相关联的产品和服务中执行的操作，包括通过本站集中展示您的个人资料、优惠权益、现金奖励。我们会尊重您对本站服务和本站账户设置所做的选择。
        <br> 4.4为您提供商品或服务信息展示：
        在您使用我们服务过程中，为识别账号异常状态、识别订单交易风险、交易安全以及系统运行安全、了解产品适配性、向您提供更契合您需求的页面展示和搜索结果，我们会收集您在使用我们服务过程中产生的相关信息，包括：设备信息：我们会根据您在软件安装或使用中的具体操作，接收并记录您所使用的设备相关信息（包括硬件型号、MAC地址、版本信息、操作系统、设备设置、唯一设备标识符（IMEI、IMSI、IDFV、IDFA、安卓广告主标识符）、电话状态、设备环境、SIM卡信息、终端制造厂商。
        <h3>五、需要特别说明的是，本政策仅适用于本站产品及服务，不适用您通过我们的产品/服务而接入的其他第三方产品/服务（包括但不限于任何第三方网站以及第三方服务提供者，如第三方支付），而应当适用该第三方的隐私政策或类似声明，我们对于前述第三方收集、使用您个人信息的行为及后果不承担任何责任。我们会不时地检查或更新我们的功能或服务，更新隐私政策，如果某一功能或服务未在前述说明中且收集了你的信息，我们会通过页面提示、弹窗提示、网站公告等方式向你说明信息收集的内容、范围和目的，以征得您的同意。若您在阅读完本政策后对本政策或者与本政策有关的事宜有任何问题的，请及时与我们客服联系。 </h3>
      </div><br>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}
</style>