<template>
  <div class="base-header">
    <div class="nav2">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          :class="{
            active: $route.name == item.path,
          }"
          @click="goMenu(item.id, item.path)"
        >
        <img :src="item.iconimg"	style="height: 28px !important;margin-right: 5px;" :style="index==1 || index==0?'height: 24px':''" />
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "base-header",
  data() {
    return {
      menu: [
        {
          id: 1,
          img1: require("@/assets/img/nav2/b1.png"),
          img2: require("@/assets/img/nav2/b11.png"),
          icon: "iconfont icon-shouye ",
          iconimg:require("@/assets/navtop/box.png"),
          selState: true,
          path: "Index",
          child: ["Index", "Openbox"],
          // value: "箱子大厅",
          value: "盲盒大厅",
        },
        {
          id: 2,
          img1: require("@/assets/img/nav2/b2.png"),
          img2: require("@/assets/img/nav2/b22.png"),
          icon: "iconfont icon-icon_duizhan ",
          iconimg:require("@/assets/navtop/battle.png"),
          selState: false,
          path: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          // value: "盲盒对战",
          value: "饰品对战",
        },
        {
          id: 3,
          img1: require("@/assets/img/nav2/b3.png"),
          img2: require("@/assets/img/nav2/b33.png"),
          selState: false,
          icon: "hxd-iconfont hxd-roll",
          iconimg:require("@/assets/navtop/house.png"),
          path: "Arena",
          child: ["Arena", "ArenaRoom"],
          value: "ROLL房",
        },
        {
          id: 4,
          img1: require("@/assets/img/nav2/b4.png"),
          img2: require("@/assets/img/nav2/b44.png"),
          icon: "iconfont icon-lucky_right",
          iconimg:require("@/assets/navtop/rocket.png"),
          selState: false,
          path: "Ornament",
          child: [
            "Ornament",
            "OrnamentOpen",
            "OrnamentHistory",
            "OrnamentHistory2",
          ],
          // value: "幸运饰品",
          value: "升级追梦",
        },
       /* {
          id: 5,
          img1: require("@/assets/img/nav2/b5.png"),
          img2: require("@/assets/img/nav2/b55.png"),
          selState: false,
          icon: "hxd-iconfont hxd-shangcheng",
          path: "Roll",
          child: ["Roll"],
          value: "饰品商城",
        }, */
        {
          id: 6,
          img1: require("@/assets/img/nav2/b6.png"),
          img2: require("@/assets/img/nav2/b66.png"),
          selState: false,
          icon: "hxd-iconfont hxd-mianfeijinbi",
          iconimg:require("@/assets/navtop/social.png"),
          path: "Spread",
          child: ["Spread"],
          // value: "活动推广",
          value: "分享推荐",
        },
        {
          id: 7,
          img1: require("@/assets/img/nav2/b8.png"),
          img2: require("@/assets/img/nav2/b88.png"),
          selState: false,
          icon: "iconfont icon-beibao",
          iconimg:require("@/assets/navtop/wallet.png"),
          path: "Payment",
          child: ["Payment"],
          value: "玩家充值",
        },
      ],
    };
  },
  methods: {
    goMenu(id, path) {
      this.$emit("goMenu", id, path);
    },
  },
};
</script>

<style lang="less" scoped>
i {
  margin-right: 5px;
}
.icon-beibao {
  font-size: 26px;
}
.hxd-mianfeijinbi {
  font-size: 32px;
}
.icon-lucky_right {
  font-size: 22px;
}
.hxd-shangcheng {
  font-size: 30px;
}
.icon-shouye {
  font-size: 30px;
}
.icon-icon_duizhan {
  font-size: 30px;
}
.hxd-roll{
  font-size: 30px;
}
.icon-icon_duizhan{
  font-size: 30px;
}
.icon-shouye{
  font-size: 18px;
}

.base-header {
  padding-left: 50px;
  .nav2 {
    height: 42px;
    ul {
      display: flex;
      height: 100%;
      .active {
        color: #ff9b0b;
      }
      li {
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 25px;
        line-height: 38px;
        margin-left: 10px;
        white-space: nowrap;
        font-weight: bold;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .base-header{
    position: fixed;
    top: 82px;
    background-color: #20222b;
    font-size: 14px !important;
    width: 38vw;
    left: 0;
    padding-left:0px !important;
    transition: all 200ms ease-in-out;
    z-index:999;
  }
  .base-header .nav2 ul{
    display: inline;
  }
  .base-header .nav2 ul li{
    overflow: hidden;
    width: 25vw;
    margin: 2px 0;
    font-size: 15px;
    cursor: pointer;
  }
}
</style>
